'use client'

import { useEffect } from "react";

function goToTop() {
    $("#back-to-top").hide();
    $(window).scroll(function () {
        if ($(window).scrollTop() > 100)
            $("#back-to-top").fadeIn();
        else
            $("#back-to-top").fadeOut();
    });
    $("#back-to-top").on("click", function () {
        $('body,html').animate({ scrollTop: 0 }, 1000);
        return false;
    });
}

export default function BottomBar() {
    useEffect(() => { goToTop(); }, []);
    return (
        <div id="bottom-bar-info" className="show-footer">
            <a className="col1" href="/" title="SELECT MULTIMARCAS">
                <i className="mdi mdi-home"></i>
            </a>
            <a className="col2" href="https://api.whatsapp.com/send?phone=5511976500420&amp;text=Olá, gostaria de informações sobre o SELECT MULTIMARCAS" rel="nofollow" target="_blank" title="Entre em contato pelo whatsapp com SELECT MULTIMARCAS">
                <i className="mdi mdi-whatsapp"></i>
            </a>
            <a className="col4" href="tel:+5511976500420" title="LIGUE AGORA PARA SELECT MULTIMARCAS">
                <i className="mdi mdi-phone-in-talk"></i>
            </a>
            <div id="back-to-top">
                <a className="top arrow" href="#top">
                    <i className="mdi mdi-arrow-up"></i>
                </a>
            </div>

        </div>
    );
}